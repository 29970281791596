<template>
    <div>
      <!-- 供应商返修单 -->
      <list-search :childData="childData"  @onSearch="onSearch" @clearSearch="clearSearch"></list-search>
      <global-table :tableField="tableField"  :tableData="tableData" :paginationData="paginationData" @tableAction="tableAction" ></global-table>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { requestForm } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { SearchList, SearchData, tableField, tableData, formData } from './js/supplierRepair'
export default {
  components: Component.components,
  name: 'SupplierRepair',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      tableField: tableField,
      tableData: tableData,
      searchdata: { pageNum: 1, pageSize },
      paginationData: { total: 1, pageSize },
      pageNum: 1,
      pageSize: pageSize,
      formData: formData
    }
  },
  mounted () {
    this.SupplierRepairList(this.searchdata)
  },
  methods: {
    SupplierRepairList (data) {
      requestForm('/api/repair/repair/querySupplierList', 'POST', data).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'SupplierRepairDetail' }, query: { id: data.id } })
        this.$emit('OpenView', { openView: 'SupplierRepairDetail' })
      }
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD HH:mm:ss')
    },
    // 查询
    onSearch (data) {
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      if (data.createDate) {
        this.searchdata.endDate = dayjs(data.createDate[1]).format('YYYY-MM-DD 23:59:59')
        this.searchdata.startDate = this.dateFormate(data.createDate[0])
      }
      this.searchdata.pageNum = 1
      this.searchdata.pageSize = this.pageSize
      this.SupplierRepairList(this.searchdata)
    },
    // 清空
    clearSearch (data) {
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      this.searchdata.pageNum = 1
      this.searchdata.pageSize = this.pageSize
      this.SupplierRepairList(this.searchdata)
    },
    // 分页
    pageChange (data) {
      this.searchdata.pageNum = data
      this.SupplierRepairList(this.searchdata)
    }
  }

}

</script>
<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
