import md5 from 'js-md5'
export var SearchList = [
  { labe: '返修单号', code: 'ordersCode', type: 'input' },
  { labe: '公司名称', code: 'companyCodeName', type: 'input', placeholder: '编码/名称' },
  {
    labe: '创建日期',
    code: 'createDate',
    type: 'daterange'
  },
  {
    labe: '返修单状态',
    code: 'repairState',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '待我确认', value: '1' },
      { label: '待客户修改', value: '2' },
      { label: '待客户发货', value: '3' },
      { label: '待我收货', value: '4' },
      { label: '待我发货', value: '5' },
      { label: '待客户收货', value: '6' },
      { label: '待客户确认', value: '7' },
      { label: '已完成', value: '8' }
    ]
  },
  {
    labe: '返修单类型',
    code: 'repairType',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: 'RMA返修', value: '1' },
      { label: 'DOA返修', value: '2' }
    ]
  }
]
export var SearchData = {
  ordersCode: '',
  companyCodeName: '',
  startDate: '',
  // createDate: '',
  endDate: '',
  repairState: '',
  repairType: ''
}
export var tableField = [
  { label: '返修单号', code: 'repairOrders', type: 'input', width: '' },
  { label: '公司名称', code: 'companyName', type: 'input', width: '' },
  {
    label: '类型',
    code: 'repairType',
    type: 'function',
    handle: (index, data) => {
      return data.repairType === '1' ? 'RMA返修' : 'DOA返修'
    }
  },
  { label: '创建人', code: 'createUserId', type: 'input', width: '' },
  { label: '创建日期', code: 'createDate', type: 'input', width: '' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '',
    handle: (index, data) => {
      const color = md5(data.status + '').substring(0, 6)
      const label = data.status === '1' ? '待我确认' : data.status === '2' ? '待客户修改'
        : data.status === '3' ? '待客户发货' : data.status === '4' ? '待我收货' : data.status === '5' ? '待我发货' : data.status === '6' ? '待客户收货'
          : data.status === '7' ? '待客户确认' : '已完成'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]
export var tableData = []
export var formData =
{
  companyCodeName: '',
  endDate: '',
  ordersCode: '',
  pageNum: '',
  pageSize: '',
  repairState: '',
  repairType: '',
  startDate: '',
  supplierNameCode: ''
}
